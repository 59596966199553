
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@200;400&display=swap');

body {
  margin-top: 0px;
  font-family: 'Kanit', sans-serif; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  background-color: #ffffff;     
  padding-top: 0px;
  margin: 0;
  font-family: 'Kanit', sans-serif; 
}
h1, h2,h3,h4,h5,h6,p, ul, li {
  font-family: 'Kanit', sans-serif;
 
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: rgb(255, 255, 255);
  padding: 0;
  margin-top: 20px;
  height: auto;
  margin-bottom: 0px; 
}
ul.header li a {
  color: rgb(3, 3, 3);
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  /* background-color: #FFF; */ 
  padding: 20px;
  padding-top: 50px;
  font-size: 18px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.active {
  background-color: #3ce608;
}
.imglogo {
  height: 40px;
    width: 40px;
    padding: 0 20px;
}
.container{
  max-width: 1080px;
}
.card{
  border: solid 0px;
}

