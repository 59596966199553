@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@200&display=swap);

body {
  margin-top: 0px;
  font-family: 'Kanit', sans-serif; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  background-color: #ffffff;     
  padding-top: 0px;
  margin: 0;
  font-family: 'Kanit', sans-serif; 
}
h1, h2,h3,h4,h5,h6,p, ul, li {
  font-family: 'Kanit', sans-serif;
 
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: rgb(255, 255, 255);
  padding: 0;
  margin-top: 20px;
  height: auto;
  margin-bottom: 0px; 
}
ul.header li a {
  color: rgb(3, 3, 3);
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  /* background-color: #FFF; */ 
  padding: 20px;
  padding-top: 50px;
  font-size: 18px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}
.active {
  background-color: #3ce608;
}
.imglogo {
  height: 40px;
    width: 40px;
    padding: 0 20px;
}
.container{
  max-width: 1080px;
}
.card{
  border: solid 0px;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


